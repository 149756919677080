import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Libra Woman</h4>
              
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra Woman</h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                  
            <p className="playfair text-black text-xl md:text-3xl">Libra Women</p>
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Libra Woman Traits:</p> Charm and beauty, humanitarianism, natural leader, cheerful and extroverted, passionate and tactful
<br/>
<p className="font-bold text-orange-500 inline-block mr-2">Compatibility Signs for Libra woman:</p> Gemini, Leo, Sagittarius, and Aquarius
 <br/><br/>
The libra woman is a creature of the utmost beauty, charm, and wit. She is generous, kind, and loves the finer things in life. She is a born leader and enjoys the spotlight on her. She is not one to shy away from conflict and will seek out arguments and debates, often ending in an intense discussion that leaves both parties better for the experience.
 <br/><br/>
She's strong yet sensitive; she's cunning, yet caring; she's independent yet needs love and support; she's willing to help others but has pride and dignity. She loves justice but hates conflict; she's a big dreamer and a realist; she's passionate but level-headed. She's a whole person and not just a list of traits.
 <br/><br/>
The Libras are the intellectuals of the zodiac. They like to think things through and often do so at length. They value sincerity and honesty and aren't afraid to show their feelings. They make good mediators and are usually the first to offer to solve conflicts.
 <br/><br/>
 You are intuitive, kind, and generous. You're also a people pleaser and a bit of a peacemaker. You're not one to shirk responsibility, and you're very concerned with fairness. You value harmony and balance, and you're an idealist. Venus rules you. Thus, you love to spend money on luxury and beauty.
 
<br/><br/>  
Your horoscope sign is a strong tool for comprehending your personality, your relationship and love compatibility on a deeper level. Discover more about yourself with your janampatri free!!!
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Let's take a look at Libra women's traits</p>
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Charm and beauty</p><br/><br/>
The gentlest and most benevolent sign of the zodiac, Libra Woman is known for her charm and beauty. She is kind, diplomatic, and compassionate; she loves to help others in times of need. But don't let her soft appearance and sweet disposition fool you: she can be a fierce and powerful fighter when she sets her mind to something. Born leaders and protectors, libra women, are often the center of attention at social gatherings.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Intelligence</p><br/><br/>
With a high charisma, you have the ability to connect easily with other people, which can be a great asset in any social situation. You are brilliant and have an active mind which helps you attract many people towards yourself. Though you are sociable and like to help others, you must ensure that you don't let others put you down. If you stand up for yourself, others will respect you, regardless of gender.
 
 
  <br/><br/>
 
  <p className="playfair text-black text-xl md:text-3xl">Humanitarianism</p><br/><br/>
As a scale symbolizes you, you have a good sense of right and wrong. If you find someone doing things incorrectly, you make it suitable. You also have a defense attorney in you. An active social activist is hidden inside of you as well.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Social</p><br/><br/>
You are sociable and like to spend time with others. You are good at making friends, and often have a large circle of friends. You are good at working in teams, and like to assist others in difficult situations. You are not a people pleaser, but you have a big heart and are willing to help others.
 
 <br/><br/>
 <p className=""></p>Optimistic: You are an eternally optimistic, and life constantly throws you surprises. You are friendly and social, and you enjoy being the centre of attention. You are very good at making a good impression on others, and you can also turn it to talking about yourself as much as you want. You are also very good at maintaining long-term relationships, and you are not easily offended.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Natural Leader</p><br/><br/>
Your cardinal nature makes you a natural leader and also a trendsetter.Your high energy and intellect can be a great asset to your business.
You have a remarkable ability to work with people and get your point across. You are innovative, creative, and can bring a lot. You are also very self-assured and have amazing self-esteem. You are socially skilled and can work well in a team.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Positive and extroverted</p><br/><br/>
You face difficulty in hiding your feelings. The people around you may call you a chatterbox as you always speak what you want to. Your optimistic approach makes you famous in your circle.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Passionate and tactful</p><br/><br/>
You are an achiever as you are very much passionate about your goals. A being strongly influenced by the concept of the libra is capable of great emotional intensity and often displays excellent strength. It is easy to forget that the female is also the most humble of any other divine gender. A passionate approach helps you develop new projects and new ideas to achieve your goals. Also, it allows you to come up with new solutions to every problem. You're an expert in handling situations.
 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">How is Libra a woman in love and relationships?</p>
 <br/><br/>
The planet Venus rules Libra women so they admire beauty and loveliness in all forms: music, art, decoration, people, etc. Libra women are often interested in people and other things, and in Libra, they seek complete relationships with their partners. All relationships have an emotional component, and Libra women are interested in how to build emotionally fulfilling relationships. Libra women want to feel valued, appreciated, and more respected than now.
 <br/><br/>
You know that relationships are a two-way street. You have learned that you can't just run through life without being committed to anyone. You understand that relationships require work, but you also know that they are worth the effort. Commitment means putting in the effort to maintain a strong bond with someone.
You've been in relationships before, learning that no relationship is without its challenges. You know that a relationship requires two people willing to work on it and that without both people working, the relationship will most likely fail. 
 <br/><br/>
You are committed to your relationships, and you are willing to work hard to make them last. But that doesn't mean that you're always happy with the compromises you have to make.
You should never try to sacrifice your needs for the sake of the relationship, but at the same time, you should be willing to compromise on issues that are important to the other person. When you find someone special, you are at your happiest. You carefully make decisions when it concerns your heart.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">To know more about your relationship, talk to our astrologers.</p>
 
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Libra Women</p><br/><br/>
Libra is the sign of partnership. It is known as the symbol of balance and harmony and the personification of these qualities, so it is no surprise that Libra women are so relationship-focused. They need harmony and balance in their life to feel truly fulfilled, which is why they are so attracted to partners that are reliable and can be counted on. However, Libra women also need to feel like their partner is comfortable with them having a strong influence over the relationship.
 
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Be ultra-polite and congenial:</p> If you want to attract Libra women, you should not be aggressive, rude, or loud-mouthed. As they hate arguments and find it hard to deal with heavy emotions and undercurrents, being overly affectionate and demonstrative will only make them uncomfortable. Instead, try to be open-minded and listen to them without judgment when they have something to say. 
 <br/><br/>
They will feel much more comfortable talking to you and sharing their opinions when you don't make them feel like they are being judged for them.
Be friendly to strangers and make small talk. Be courteous to everyone.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Lend a listening ear: </p>They're interested in talking and listening about psychology, genetics, and so forth and are very good at it. They're also good at making observations. And they love to listen. So doing this is a great way to get them interested in your content and discuss how they would be able to incorporate your content into their lives. You have to be a good listener and good at communication if you want libra women in your life. You need to be kind and patiently listen to them with all the pros and cons.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Always look gorgeous:</p> Physical appearance is significant for Libra women. Libra women value beauty. They are very proud of their bodies, and their beauty is their most important asset. This is what she wants in a man. Libra women are attracted to a man who will complement their good looks.
 

<br/><br/>

            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
